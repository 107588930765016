import React from 'react';
import './styles.scss'

class HomeSeparador extends React.Component {
  render(){
    return(
      <section id='home-separador'>
        <div className='fondo'> </div>
      </section>
    )
  }
}

export default HomeSeparador;
